import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getTextTracks } from "../store/getters";

const dashPlayer = {    
    _appconfigLocal: localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : '',
    created() {
        eventBus.$on('traksActions', (data)=>{
            switch(data?.type) {
                case 'quality':
                    this.setVideoQuality(data);
                    break;
                case 'subtitle':
                    this.setSubtitle(data);
                    break;
                case 'audio':
                    this.setAudioLanguage(data);
                    break;
                default :
                    break;
                
            }
        })
    },
    computed: {
        ...mapGetters(["appConfig", "getTextTracks", "getAudioTracks", "getVideoTracks"]),
    },
    methods: {
        ...mapMutations(["setVideoTracks", "setAudioTracks", "setTextTracks"]),

        initiateShaka(video) {   
            eventBus.$emit('playerLoader', true);
            window.shakaPlayer = new window.shaka.Player(video);
        },

        loadShakaPlayer(contentInformation, video) {
            let mpdURL = contentInformation?.streamfilename;
            // let licenseServerUrl = `https://vdrm.mobiotics.com/prodv4/proxy/v1/license/widevine`;
            let licenseServerUrl = process.env.VUE_APP_LIVE_WIDEVINE_LICENSESERVERURL_URL;
            if (window.shakaPlayer) {
                window.shakaPlayer.configure({
                    drm: {
                        servers: {
                            'com.widevine.alpha': licenseServerUrl
                        },
                        advanced: {
                            'com.widevine.alpha': {
                                'videoRobustness': 'SW_SECURE_CRYPTO',
                            }
                        }
                    },
                    streaming: {
                        retryParameters: {
                            maxAttempts: 3,
                            baseDelay: 1000, // 1 second
                        },
                    },
                });
                

                // Debug logs, when the default of INFO isn't enough:
                // window.shakaPlayer.setLevel(2);

                window.shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
                    var StringUtils = shaka.util.StringUtils;
                    var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;
                    let sessionToken = localStorage?.getItem('sessionToken');
                    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                        var rawLicenseRequest = new Uint8Array(request.body);
                        request.headers['Content-Type'] = "application/json";
                        request.headers["X-SESSION"] = sessionToken;
                        var wrapped = {};
                        wrapped.payload = btoa(String.fromCharCode.apply(null, rawLicenseRequest));
                        wrapped.contentid = contentInformation?.channelid ? contentInformation?.channelid : contentInformation?.contentid;
                        wrapped.providerid = process.env.VUE_APP_PROVIDERID;
                        wrapped.timestamp = Math.floor((new Date()).getTime() / 1000);
                        wrapped.drmscheme = 'WIDEVINE';
                        var customData = {}
                        customData.packageid = contentInformation.packageid;
                        customData.drmtoken = contentInformation.drmToken.success;
                        // customData.drmtoken = "zoWawFaAHXYehKK";

                        wrapped.customdata = customData;

                        var wrappedJson = JSON.stringify(wrapped);

                        request.body = new Uint8Array(wrappedJson.length);
                        for (var i = 0; i < wrappedJson.length; ++i) {
                            request.body[i] = wrappedJson.charCodeAt(i);
                        }

                    }
                });

                window.shakaPlayer.getNetworkingEngine().registerResponseFilter(function (type, response) {


                    // console.log(response)
                    var StringUtils = shaka.util.StringUtils;
                    var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;
                    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {

                        let contentType = response.headers['content-type'];
                        var wrappedArray = new Uint8Array(response.data);
                        var wrappedString = String.fromCharCode.apply(null, wrappedArray);
                        var wrapped = JSON.parse(wrappedString);

                        var rawLicenseBase64 = wrapped.body;

                        // decode it to a string.
                        var rawLicenseString = atob(rawLicenseBase64);
                        // convert that string into a Uint8Array and replace the response data to feed it to the Widevine CDM.
                        response.data = new Uint8Array(rawLicenseString.length);
                        for (var i = 0; i < rawLicenseString.length; ++i) {
                            response.data[i] = rawLicenseString.charCodeAt(i);
                        }

                    }
                });

                window.shakaPlayer.load(mpdURL).then(() => {
                    video?.play();
                    eventBus.$emit('playerLoader', false);
                    this.fetchVarientTracks();
                    this.fetchTextTracks();
                    this.fetchAudioTracks();

                }).catch((onError) => {
                    console.error('Error code', onError.code, 'object', onError);
                    eventBus.$emit('playerError', onError.code);
                });

                window.shakaPlayer.addEventListener('buffering', (event) =>{
                    if (event.buffering) {
                      
                      eventBus.$emit('playerLoader', true);
                    } else {
                      
                      eventBus.$emit('playerLoader', false);
                    }
                });

                window.shakaPlayer.addEventListener('error', (error) => {
                    console.error('Error code', error.code, 'object', error);
                    // eventBus.$emit('playerError', 'unhandledNativeError');
                    if (error instanceof Error) {
                        // shaka crashed with an unhandled native error
                        eventBus.$emit('playerError', 'unhandledNativeError');
                    }
        
                    if (error.severity === shaka.util.Error.Severity.CRITICAL) {
                        // handle fatal error, playback can not continue
                        eventBus.$emit('playerError', 'playback can not continue');
                    } else {
                        // handle non-fatal error, playback can continue
        
                    }
                });

            }

        },

        destroyShaka(videoElement) {
            if(window.shakaPlayer) {
                window.shakaPlayer.destroy().then(() => {
                    console.log('Player destroyed');
                    window.shakaPlayer = null; // Clean up the reference
                }).catch((error) => {
                    console.error('Error destroying player:', error);
                });
            }
            if (videoElement) {
                videoElement.src = ''; // Clear the video source
                videoElement.load(); // Reset the media element
            }
        },

        fetchVarientTracks() {
            const videoTracks = window.shakaPlayer.getVariantTracks();
            this.setVideoTracks(videoTracks);
        },

        fetchTextTracks() {
            const textTracks = window.shakaPlayer.getTextTracks();
            textTracks && this.setTextTracks(textTracks);
        },

        fetchAudioTracks() {
            const audioTracks = window.shakaPlayer.getAudioLanguagesAndRoles();
            this.setAudioTracks(audioTracks);
        },

        setVideoQuality(data) {
            if (data?.value?.key === 'auto') {
                window.shakaPlayer?.configure({ abr: { enabled: true } });
            } else {
                window.shakaPlayer?.configure({ abr: { enabled: false } });
                window.shakaPlayer?.selectVariantTrack(this.getVideoTracks?.find(resolution => resolution?.height == data?.value?.key), true);
            }
        },

        setSubtitle(data) {
            if (data?.value?.key === 'off') {
                window.shakaPlayer?.setTextTrackVisibility(false);
            } else {
                window.shakaPlayer?.selectTextTrack(this.getTextTracks?.find(track => track?.id == data?.value?.key));
                window.shakaPlayer?.setTextTrackVisibility(true);
            }
        },

        setAudioLanguage(data) {
            window.shakaPlayer?.selectAudioLanguage(data?.value?.key);
        }


    }
}

export default dashPlayer;