import { eventBus } from "@/eventBus";

const fairPlayer = {
    _certificate: "",
    _playerKeyId: "",
    _keySystem: "",
    _contentInformation: "",
    _sdkValue: "",
    _appconfigLocal: localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : '',
    methods: {
        initiateHls (video) {
            eventBus.$emit('playerLoader', true);
            window.hlsPlayerObj = new window.Hls();
            window.hlsPlayerObj.attachMedia(video);
        },
        initiateFairPlay (contentInformation, video) {
            this._contentInformation = contentInformation;
            video.addEventListener('webkitneedkey',  this.videoPlayerWebkitKeyNeededCb);
            this.loadCertificate().then((res) => {
                // videotag.src = 'https://stream3.keeott.com/bpk-tv/FLOWERS/output/index.m3u8'
                // videotag.src = 'https://stream3.keeott.com/bpk-tv/TAMILAN_TELEVISION/output/index.m3u8';
                // videotag.src = 'https://cdn.noorplay.com/RuB69qNY_fWIIZRqi3paPYqnZ_enc.m3u8';

                video.src = this._contentInformation.streamfilename;
                this.fetchVarientTracksFairPlay(video);
                this.fetchTextTracksFairPlay(video);
                this.fetchAudioTracksFairPlay(video);
                eventBus.$emit('playerLoader', false);             
            }).catch((error) => {
                eventBus.$emit('playerError', error);
            })
        },
        videoPlayerWebkitKeyNeededCb (event)  {
            // alert("videoPlayerWebkitKeyNeededCb")
            //onneedkey code block.
            var video = event.target;
            var initData = event.initData;
            this._playerKeyId = this.extractContentId(initData);
        
            this._sdkValue = this.extractContentIdNew(this.arrayToStringNew(initData));
        
        
            initData = this.concatInitDataIdAndCertificate(initData, this._playerKeyId, this._certificate);
        
        
            if (!video.webkitKeys)
            {
                this.selectKeySystem();
                video.webkitSetMediaKeys(new WebKitMediaKeys(this._keySystem));
            }
        
            if (!video.webkitKeys)
                throw "Could not create MediaKeys";
        
            var keySession = video.webkitKeys.createSession("audio/aac", initData);
            
            if (!keySession)
                throw "Could not create key session";
        
            keySession.contentId = this._playerKeyId;
        
            this.waitForEvent('webkitkeymessage', (event) => {
              
                var session = event.target;
                var message = event.message;
                var sessionId = event.sessionId;
                let sessionToken = localStorage?.getItem('sessionToken');

                let requestHeaders = new Headers();
                requestHeaders.append('Content-type', 'application/json');
                // requestHeaders.append("X-SESSION", sessionToken);
        
                let wrappedLicenseRequest = {};
        
                wrappedLicenseRequest['payload'] = this.base64EncodeUint8Array(message);
        
        
                wrappedLicenseRequest['drmscheme'] = 'FAIRPLAY';
                wrappedLicenseRequest['contentid'] = this._contentInformation?.channelid ? this._contentInformation?.channelid : this._contentInformation?.contentid;
                wrappedLicenseRequest['providerid'] = process.env.VUE_APP_PROVIDERID;          
                wrappedLicenseRequest['timestamp'] = Math.floor((new Date()).getTime() / 1000);            
                var customData = {};
                customData.packageid = this._contentInformation?.packageid;
                // customData.packageid = "y6RJwfwpqdalgkNF"
                customData.drmtoken = this._contentInformation?.drmToken?.success;
                // customData.drmtoken = "zoWawFaAHXYehKK";

                // customData.drmToken = "ciTawGaityhYeri";

                customData['keyid'] = this._sdkValue;

                wrappedLicenseRequest['customdata'] = customData;
                let licenseRequestJsonString = JSON.stringify(wrappedLicenseRequest);
                
                let requestOptions = {
                  method: 'POST',
                  headers: requestHeaders,
                  body: licenseRequestJsonString,
                };
                
                // fetch( 'https://vdrm.mobiotics.com/prodv4/proxy/v1/license/fairplay', requestOptions);
                let licenseServerUrl =  process.env.VUE_APP_LIVE_FAIRPLAY_LICENSESERVERURL_URL;
                fetch(licenseServerUrl, requestOptions)
                .then((response) => {
        
                    if(response.ok == true) {
                        return response.json();
                    }
                }).then(data => {
                  let key = this.base64DecodeUint8Array(data.body);
                  session.update(key);
                //   this._videoResolveCb(true);
                }).catch((error) => {
                    // this._abortController = null;
                    
                    // this._videoRejectCb(false);
                });
        
            }, keySession);
        
            this.waitForEvent('webkitkeyadded', (e) => {
                // window.console.error('A decryption key error was encountered');
                // console.log('Key error | webkitkeyadded', e);
            }, keySession);
        
            this.waitForEvent('webkitkeyerror', (error) => {
                //onkeyerror code block.
                
                window.console.error('A decryption key error was encountered');
        
            }, keySession);
        
        },        
        waitForEvent (name, action, target) {
            target.addEventListener(name, function() {
                action(arguments[0]);
            }, false);
        },
        arrayToString (array) {
            var uint16array = new Uint16Array(array.buffer);
            return String.fromCharCode.apply(null, uint16array);
        },
        
        extractContentIdNew (data) {
          return data.split("skd://")[1]
        },
        
        extractContentId (initData) {
            this._playerKeyId = this.arrayToString(initData);
            // contentId is passed up as a URI, from which the host must be extracted:
            var link = document.createElement('a');
            link.href = this._playerKeyId;
            return link.hostname;
        },
        stringToArray (string) {
            var buffer = new ArrayBuffer(string.length*2); // 2 bytes for each char
            var array = new Uint16Array(buffer);
            for (let i=0, strLen=string.length; i<strLen; i++) {
                array[i] = string.charCodeAt(i);
            }
            return array;
        },        
        concatInitDataIdAndCertificate (initData, id, cert) {
            if (typeof id == "string")
                id = this.stringToArray(id);
            // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
            var offset = 0;
            var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
            var dataView = new DataView(buffer);
        
            var initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
            initDataArray.set(initData);
            offset += initData.byteLength;
        
            dataView.setUint32(offset, id.byteLength, true);
            offset += 4;
        
            var idArray = new Uint16Array(buffer, offset, id.length);
            idArray.set(id);
            offset += idArray.byteLength;
        
            dataView.setUint32(offset, cert.byteLength, true);
            offset += 4;
        
            var certArray = new Uint8Array(buffer, offset, cert.byteLength);
            certArray.set(cert);
        
            return new Uint8Array(buffer, 0, buffer.byteLength);
        },        
        base64EncodeUint8Array (input) {
            var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;
        
            while (i < input.length) {
                chr1 = input[i++];
                chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
                chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
        
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
        
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                    keyStr.charAt(enc3) + keyStr.charAt(enc4);
            }
            return output;
        },        
        base64DecodeUint8Array (input) {
            var raw = window.atob(input);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
        
            for(let i = 0; i < rawLength; i++)
                array[i] = raw.charCodeAt(i);
        
            return array;
        },
        loadCertificate() {
            return new Promise((resolve, reject) => {
                
                let requestHeaders = new Headers();
                requestHeaders.append('Pragma', 'no-cache');
                requestHeaders.append('Cache-Control', 'max-age=0');
                
                let requestOptions = {
                  method: 'GET',
                  headers: requestHeaders,
                  redirect: 'follow',
                };
            
                fetch('/certificates/noor.cer', requestOptions)
                .then((response) => {
                    if(response.ok == true) {
                        return response.arrayBuffer();
                    } else {
                        reject(response.status);
                    }
                }).then(data => {
                  this._certificate = new Uint8Array(data);
                  resolve(true);
                }).catch((error) => {
                    
                    reject(false);
                });
            });
        },
        selectKeySystem () {
            if (WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4"))
            {
                this._keySystem = "com.apple.fps.1_0";
            }
            else
            {
                throw "Key System not supported";
            }
        },
        arrayToStringNew (array) {
          return String.fromCharCode.apply(null, new Uint16Array(array.buffer));
        },
        fetchVarientTracksFairPlay(video) {
            const videoTracks = [];
            this.setVideoTracks(videoTracks);
        },
        fetchTextTracksFairPlay(video) {
            const textTracks = video?.textTracks;
            textTracks && this.setTextTracks(textTracks);
        },
        fetchAudioTracksFairPlay(video) {
            if(video?.audioTracks) {
                const audioTracks = video?.audioTracks;
                audioTracks && this.setAudioTracks(audioTracks);
            }
        },
    }
}

export default fairPlayer;